<template>
  <div class="col-xl-9 col-lg-12 col-md-12">
    <div class="card mb-0">
      <div class="card-header">
        <router-link to="/profile/education/me" type="button">
          <i class="dropdown-icon si si-arrow-left"></i>
        </router-link>
        <h3 class="card-title">{{ title }}</h3>
        <div class="card-options">
          <router-link
            :to="
              '/profile/education/participant/' + $route.params.id + '/message'
            "
            type="button"
            class="btn btn-primary"
          >
            Eğitmene Sor
          </router-link>
        </div>
      </div>
      <div class="card-body">
        <div class="item2-gl">
          <div class="tab-content">
            <div class="tab-pane active" id="tab-11">
              <div class="text-center">
                <div class="row">
                  <template v-if="createdCalendar.length > 0">
                    <div
                      class="col-xl-4 col-lg-3 col-md-6"
                      v-for="(item, i) in createdCalendar"
                      :key="i"
                    >
                      <div class="item">
                        <div class="date">Tarih: {{ item.date }}</div>
                        <div>
                          <i class="fa fa-clock-o"></i>{{ item.begin }}
                          <i class="fa fa-clock-o"></i> {{ item.end }}
                        </div>
                        <p>Grup: {{ item.title }}</p>
                        <p>Not: {{ item.memo }}</p>
                        <button
                          v-if="item.online == 1"
                          class="btn btn-sm btn-primary"
                          @click="openVideoCall(item)"
                        >
                          <i class="fa fa-video-camera"></i>
                        </button>
                        <button
                          v-if="item.shared_files.length > 0"
                          class="btn btn-sm btn-primary"
                          @click="openFileEditor(item.shared_files)"
                        >
                          <i class="fa fa-file"></i>
                        </button>
                      </div>
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal" tabindex="-1" id="files" role="dialog">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Dosyalar</h5>
          </div>
          <div class="modal-body">
            <div v-for="(item, i) in files" :key="i">
              <a
                class="btn btn-sm btn-primary"
                :href="item.file"
                :title="item.name"
              >
                <i class="fa fa-download"></i>
              </a>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
              @click="closeFileEditor"
            >
              Kapat
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.item {
  box-shadow: 0px 3px 10px 0px #ddd;
  padding: 5px;
  border-radius: 10px;
  margin: 10px;

  transition: ease-out 0.5s;
}
.item:hover {
  box-shadow: 0px 3px 25px 0px #ddd;
  transition: ease-in 0.2s;
}
.date {
  width: 100%;
  padding: 5px;
  color: palevioletred;
}
</style>
<script>
export default {
  created() {
    this.$store
      .dispatch("educationMemberPlanGet", { id: this.$route.params.id })
      .then((value) => {
        this.title = value[0].title;
        document.title = value[0].title;
        this.days = value[0].days;
        this.joiner = value[0].participants;
        this.groups = value[0].groups;
        this.createdCalendar = value[0].calendar;
      });
  },
  data() {
    return {
      title: null,
      groups: [],
      days: [],
      createdCalendar: [],
      joiner: [],
      files: [],
    };
  },
  methods: {
    openVideoCall(item) {
      this.$router.push("/education-chat-room/" + item.id);
    },
    openFileEditor(item) {
      this.files = item;
      $("#files").modal("show");
    },
    closeFileEditor() {
      $("#files").modal("hide");
    },
  },
};
</script>